import React, { useEffect, useState } from 'react'
import './MintSection.scss';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../../redux/blockchain/blockchainActions";
import { fetchData } from "../../redux/data/dataActions";

import { toast } from 'react-toastify';
import Web3EthContract from "web3-eth-contract";

import whiteListAddresses from '../../addresses.json'
import MerkleTree from 'merkletreejs'
import keccak256 from 'keccak256'


import loading from '../../assets/images/loading.svg'

import gif from '../../assets/images/gif-img.gif'

import Background from '../background/Background';


function MintSection() {

  let observer= new IntersectionObserver(function(entries) {
    let team1=document.getElementsByClassName('MintSection-inner')[0]

    for(var i=0;i<entries.length;i++){
        if(entries[i].isIntersecting===true){
    
            switch(Math.round(entries[i].intersectionRatio*10)){

                case 2:
                    team1.style.top='0px';
                    team1.style.opacity='1';

                    break;
                case 3:
                    team1.style.top='0px';
                    team1.style.opacity='1';
                    
                    break;
            }
        
            
        }
    }
},{ threshold: [0,0.1,0.2,0.3,0.4,0.5,0.6,0.8,0.9,1] });

useEffect(()=>{
    observer.observe(document.getElementsByClassName('MintSection')[0]);
},[])


const truncate = (input, len) =>
     input.length > len ? `${input.substring(0, len)}...` : input;
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const [claimingNft, setClaimingNft] = useState(false);
  const [mintAmount, setMintAmount] = useState(1);
  const [paused,setPaused] = useState('')
  const [cost,setCost] = useState('')
  const [maxPerWallet,setMaxPerWallet]=useState('');
  const[limit,setLimit]=useState('')
  const[totalSupply,setTotalSupply]=useState('')
  const[refresh,setRefresh]=useState(0)
  const[ethereum,setEthereum]=useState(null)
  const[balanceExceeded,setBalanceExceeded]=useState('')
  const[balance,setBalance]=useState('')
  const[addressWhitelisted,setAddressWhitelisted]=useState('')


  const[publicActive,setPublicActive]=useState('')
  const[presaleActive,setPresaleActive]=useState('')

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => setModalOpen(true);
 


  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    if(paused===true){
      toast.error('Mint coming soon')
      return;
    }
    if(claimingNft||cost==='')return;
    
    let cost2 = cost;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);


    const whiteListAddressesLeaves = whiteListAddresses.map((x) => keccak256(x))
    const merkletree = new MerkleTree(whiteListAddressesLeaves, keccak256 ,{sortPairs:true})
    const merklePoof=merkletree.getHexProof(keccak256(blockchain.account))

    toast.success(`Minting your ${mintAmount > 1 ? 'Meta Vikingss' : 'Meta Viking'}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(merklePoof ,mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        toast.error("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        toast.success(() =>
          <span>
            Meta Viking Mint Successful! Visit <a href="https://opensea.io/">opensea.io</a> to view it.
          </span>
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
        setRefresh(refresh+1)
        setMintAmount(1)
      });
  };


  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();

  }, []);

  useEffect(() => {
    getData();

  }, [blockchain.account]);

  const mint = () => {
    claimNFTs();
    getData();
  }

  const connectWallet=()=>{
    // if(paused===''&&ethereum===null)return;
    dispatch(connect());
    getData();
  }

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > limit) {
      newMintAmount = limit;
    }
    if(limit===''){
      newMintAmount=1
    }
    if(limit===0){
      newMintAmount=1

    }
    setMintAmount(newMintAmount);
  };

  

  const setAmountInput = (amount) => {
    amount = Number(amount)
    if (isNaN(amount)) return;
    if (amount > limit) {
      setMintAmount(limit)
    }
    else {
      setMintAmount(amount)
    }
  }

  const initConnect=async()=>{
    const { ethereum } = window;
    // let ethereum=window?.ethereum?.providers!==undefined?window?.ethereum?.providers?.find((x) => x?.isMetaMask):window.ethereum
    // let walletProvider = window.ethereum.providers.find((x) => x.isCoinbaseWallet) // isCoinbaseWallet is part of the injected provider
    Web3EthContract.setProvider(ethereum);

  

    const address=CONFIG.CONTRACT_ADDRESS
    const abiResponse = await fetch("/config/abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const abi = await abiResponse.json();

    const SmartContractObj = new Web3EthContract(
      abi,
      address
    );
     
      // console.log(ethereum.isMetaMask)
    
    if(ethereum){
      ethereum.on("accountsChanged", (accounts) => {
        setRefresh(refresh+1)
      });
      ethereum.on("chainChanged", () => {
        window.location.reload();
      });
  
      try{
        const presaleCost=await SmartContractObj.methods.presaleCost().call();

        const publicSaleCost=await SmartContractObj.methods.publicSaleCost().call();
        const maxPerPresaleWallet=await SmartContractObj.methods.maxMintAmountPerPresaleAccount().call()

        const maxPerPublicSaleWallet=await SmartContractObj.methods.maxMintAmountPerPublicAccount().call()
        const presaleActive=await SmartContractObj.methods.presaleActive().call()
  
        const paused=await SmartContractObj.methods.paused().call();
        const totalSupply=await SmartContractObj.methods.totalSupply().call();
        // console.log(presaleActive)
        if(presaleActive){
          setCost(presaleCost)
          setMaxPerWallet(maxPerPresaleWallet)
          setPresaleActive(true)
          setPublicActive(false)
        }
        else {
          setCost(publicSaleCost)
          setMaxPerWallet(maxPerPublicSaleWallet)
          setAddressWhitelisted(true)
          setPublicActive(true)
        }
      
        setTotalSupply(Number(totalSupply))
      
        setPaused(paused)
    
      }
      catch(err){
        console.log(err)
      }
      
      // setEthereum(ethereum)
    }
    else{
      // toast.error('Install metamask!')
    }
   
  }

  useEffect(()=>{
    if(CONFIG.CONTRACT_ADDRESS==='')return;

    initConnect()

  },[refresh,CONFIG])

  useEffect(()=>{
    if(blockchain.account===null||maxPerWallet==='')return;

    blockchain.smartContract.methods.balanceOf(blockchain.account).call().then((result)=>{
      let balance=Number(result);
      setBalance(Number(result));
      let limit=Number(maxPerWallet)-result;
      if(limit<=0){
        setLimit(0)

      }
      else{
        setLimit(limit)

      }

    })

  },[refresh,blockchain.smartContract,maxPerWallet])


  useEffect(()=>{
    if(blockchain.account===null)return;

    const whiteListAddressesLeaves = whiteListAddresses.map((x) => keccak256(x));
    const merkletree = new MerkleTree(whiteListAddressesLeaves, keccak256 ,{sortPairs:true})
    const merklePoof=merkletree.getHexProof(keccak256(blockchain.account));
    const rootNode=merkletree.getRoot().toString('hex');
    let whitelisted=merkletree.verify(merklePoof,keccak256(blockchain.account),rootNode)

    //we check for totalSupply to check if the values have been retrieved
    if(whitelisted===false&&presaleActive!==''){
      setAddressWhitelisted(false)
    }
    else if(presaleActive!==''){
      setAddressWhitelisted(true)
    }
  },[blockchain.account,presaleActive])


  const[days,setDays]=useState('')
  const[hours,setHours]=useState('')
  const[minutes,setMinutes]=useState('')
  const[seconds,setSeconds]=useState('')

  const[days2,setDays2]=useState('')
  const[hours2,setHours2]=useState('')
  const[minutes2,setMinutes2]=useState('')
  const[seconds2,setSeconds2]=useState('')
  const[publicVisible,setPublicVisible]=useState(true)
  const[presaleVisible,setPresaleVisible]=useState(true)

  // Update the count down every 1 second
  useEffect(()=>{
    var timer = setInterval(function() {
  
      // Get today's date and time
      var now = new Date().getTime();
     

      // Find the distance between now and the count down date
      var distance,distance2 ;
      const ogMintDate=new Date(Date.UTC(2022,3,20,14)).getTime();
      const publicMintDate=new Date(Date.UTC(2022,3,20,20,20)).getTime();

      distance=ogMintDate-now
      distance2=publicMintDate-now

     


      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      var days2 = Math.floor(distance2 / (1000 * 60 * 60 * 24));
      var hours2 = Math.floor((distance2 % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes2 = Math.floor((distance2 % (1000 * 60 * 60)) / (1000 * 60));
      var seconds2 = Math.floor((distance2 % (1000 * 60)) / 1000);

      // setVisible(true)

      if(seconds>9||seconds===0){
        setSeconds(`${seconds}`)
        setSeconds2(`${seconds2}`)

      }
      else{
        setSeconds(`${seconds}`)

        setSeconds2(`0${seconds2}`)
      }
      if(minutes>9||minutes===0){
        setMinutes(`${minutes}`)

        setMinutes2(`${minutes2}`)
      }
      else{
        setMinutes(`0${minutes}`)
        setMinutes2(`0${minutes2}`)

      }
      if(hours>9||hours===0){
        setHours(`${hours}`)
        setHours2(`${hours2}`)

      }
      else{
        setHours(`0${hours}`)
        setHours2(`0${hours2}`)

      }
      if(days>9||days===0){
        setDays(`${days}`)
        setDays2(`${days2}`)

      }
      else{
        setDays(`0${days}`)
        setDays2(`0${days2}`)

      }
  
      if (distance < 0) {
        clearInterval(timer);
        setPresaleVisible(false)
      }
      if(distance2 < 0) {
        clearInterval(timer);
        setPublicVisible(false)
      }

    }, 1000);
  
  },[])

  console.log(limit)

  return (
 
      <div className='MintSection'>
        <Background/>
        <div className='MintSection-inner'>
          <div className='Mint-section-right'>
            <img src={gif}>
            </img>
          </div>
          <div className='MintSection-left family-orbitron'>
            <span  className='font-l weight-semi-bold text-green mt-1'>
                Monster NFT
            </span>
          
            {blockchain.smartContract !== null && 
            <div>
              <div className="font-xs text-light-gray mt-2">
                Price:
                <span className="font-xs text-white bold">
                {` ${cost/1000000000000000000} Eth per NFT`}
                </span>
              </div>
              <div className="font-xs text-light-gray mt-1">
                Limit: {maxPerWallet} per wallet
              </div>
              <div className="font-xs text-light-gray mt-1">
                { `${totalSupply} / 10,000`}
              </div>
              <div className="mt-1">
              <a className="font-xs text-white mt-1" href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}

              </a>
              </div>
              
            </div>
            }
            <div className='MintSection-left-bottom'>
              <div className='MintSection-bossNum'>

                <div className='MintSection-bossNum-top'>
                  <button onClick={() => { decrementMintAmount() }} className='mint-plus-btn   font-s bold   text-white'>
                    -
                  </button>
                  <input onChange={(e) => { setAmountInput(e.target.value) }} value={mintAmount} placeholder='1' className='font-s  text-white' />
                  <button onClick={() => { incrementMintAmount() }} className='mint-plus-btn  font-s bold  text-white'>
                    +
                  </button>
                </div>

                {/* {!presaleVisible&& */}
                <div className='MintSection-bossNum-bottom font-xs text-white'>

                
                  {/* {blockchain.account === "" || blockchain.smartContract === null ? (
                    <button onClick={() => {
                    
                    }} className='mint-button'>
                      <span className='font-s bold'>
                        Connect Wallet
                      </span>
                    </button>) : (
                    <button onClick={() => { mint() }} className='mint-button'>

                      <span className='font-s bold'>
                        Mint
                      </span>
                      
                    </button>

                  )} */}
                  {/* {claimingNft&&<img id='loading-img' className='mt-1' src={loading}></img>} */}
                
                  {
                    (blockchain.smartContract===null||blockchain.account===null)&&
                    (<button 
                      // onClick={()=>{connectWallet()}} 
                    className='font-s mint-button family-raleway  weight-bold'>
                      Connect Wallet
                    </button>)
                  }
                  {
                    blockchain.smartContract!==null&&addressWhitelisted===true&&limit>0?
                    (
                        
                      <button onClick={()=>claimNFTs()} className='font-s mint-button family-raleway  weight-bold'>
                          {claimingNft===true?(<img src={loading}/>):'MINT NOW'}
                      </button>

                    )
                    :addressWhitelisted===false?
                    <div style={{width:'100%'}} className='text-red font-m family-raleway weight-bold'>
                      {blockchain.account!==null&&
                        <div style={{textAlign:'center',paddingTop:'25px'}}>
                          <div>
                            Not Whitelisted!
                          </div>
                        </div>
                      }

                      
                      
                      </div>
                      :limit===0?
                      <div  style={{width:'100%'}}  className='text-red font-s family-raleway weight-bold'>
                        {blockchain.account!==null&&
                          <div style={{textAlign:'center',paddingTop:'25px'}}>
                            <div>
                              Mint Limit Exceeded
                            </div>
                          </div>
                      
                        }
                      </div>
                      :blockchain.account!==null?
                      <button  className='font-xs mint-button family-raleway'>
                        <img src={loading}/>
                      </button>:null
                  }
                
                  </div>
                {/* } */}

              </div>
              

          


            </div>


           

            {
              presaleVisible&&
              <div>
                <span className="font-l text-blue extra-bold mt-1">
                  Whitelist Mint 
                </span>
                <p className='font-m family-orbitron text-white mt-1'>{days}d:  {hours}h:  {minutes}m:  {seconds}s</p>
              </div>
            }
            {/* {
              publicVisible&&
              <div className="font-l text-blue mt-1">
                <span className="font-l text-blue extra-bold mt-1">
                  Public Sale Mint 
                </span>
                <p className='font-m family-orbitron text-white'>{days2}d:  {hours2}h:  {minutes2}m:  {seconds2}s</p>
              </div>
            } */}
           
          </div>
         
        </div>
      </div>
  )
}

export default MintSection
