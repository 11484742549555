import React, { useEffect, useRef, useState } from 'react';
import './FaqSection.scss'



function Faq({answer,question}) {

    const faq=useRef()
    const faqAnswer=useRef()
    const [faqOpen,setFaqOpen]=useState(false)

    const[faqAnswerHeight,setFaqAnswerHeight]=useState(0)
    const[faqHeight,setFaqHeight]=useState(0)


    useEffect(()=>{
       
        if(faqAnswer==undefined||faq===undefined)return;
        // console.log(faqAnswer.current.offsetHeight)
        setFaqAnswerHeight(faqAnswer.current.offsetHeight)
        // setFaqHeight(faq.current.offsetHeight)
        window.addEventListener('resize',()=>{
            faq.current.style.height=`100px`;
            setFaqAnswerHeight(faqAnswer.current.offsetHeight)
            // setFaqHeight(faq.current.offsetHeight)
            setFaqOpen(false)
        })
    },[faqAnswer,faq])

    const expand=()=>{

        if(!faqOpen){
            faq.current.style.height=`${100+faqAnswerHeight+20}px`;
            // faqAnswer.current.style.height=`${faqAnswerInner.current.offsetHeight}px`;
        }
        else{
            // faqQuestion.current.style.borderBottom='1px solid white';
            // faqAnswer.current.style.height=`0px`;
            faq.current.style.height=`100px`
        }
        
        console.log('what')
        setFaqOpen(!faqOpen)
    }

    return (

    <div ref={faq}  className='faq family-orbitron text-white'>
        <div onClick={()=>{expand()}} className='faq-question'>
            <span className='faq-question-title font-s weight-bold'>
                {question}
            </span>
        </div>
        <div  ref={faqAnswer} className='faq-answer'>
            <div className='faq-answer-inner'>
                <p className='font-xs'>
                    {answer}
                </p>
            </div>
        </div>
    </div>


    );
}

export default Faq;
