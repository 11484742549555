import React from 'react';
import './FaqSection.scss'
import Faq from './Faq'


function FaqSection() {
 
  return (
    <div className='faq-section'>
        <div className='font-xl family-orbitron weight-bold text-green'>
            FAQ
        </div>
        <div className='faq-section-inner'>
            <Faq
                question='What is an NFT?'
                answer="NFT stands for “non-fungible token”. An NFT is basically data that is accounted for in a digital ledger, and that data represents something specific and unique. An NFT can, for example, represent a unique piece of art or game token."
            />
            <Faq
                question='Where can I view my NFT’s?'
                answer="Once minted, simply connect to your open sea/ meta mask account to view your NFT’s"
            />
            <Faq
                question="What is the metaverse?"
                answer="The metaverse can be defined as a simulated digital environment the uses augmented reality (AR), virtual reality (VR) and blockchain technology. Users can play games, buy and sell land, avatars and more often using cryptocurrency. Many believe that the metaverse is the future."
            />
            <Faq
                question='Why do we need royalties?'
                answer='The amount of royalties is fixed at 10% to finance the monsters of the meta project. We have the ambition to organize multiple events around the world in order to strengthen then community and build a network of entrepreneurs and investors with the same mindset and common interest. The funds collected will be beneficial to the holders. This percentage may decrease over time.'
            />
            <Faq
                question='Why this project?'
                answer='This project is different from others as we are here to build the most amazing community the nft world has ever seen. It’s not about a quick buck for us and this will be proven with our crazy utilities and how much our community get back. That’s without mentioning that we wrote 50% of the roadmap and the other 50% is down to you guys. You will be able to come together and plan the next steps for the MonstersNft gang. Will you be taking us deep into the metaverse? I hope so!'
            />
            <Faq
                question="What blockchain will you be using?"
                answer="All MonstersNft will be collected on the Ethereum blockchain."
            />
            <Faq
                question="What does “MINT” mean?"
                answer="“Minting” an NFT is, in more simple terms, uniquely publishing a token on the blockchain to make it purchasable.
                How much does it cost to buy a MonstersNft?
                The price of minting and sale will be announced soon."
            />
        </div>
    </div>
  );
}

export default FaqSection;
