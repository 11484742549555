import React from 'react'

import './Background.scss'

function Background() {
    return (
        // <div classNameName="overlay"></div>
       
        <div className="container"><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg><svg className="shape" viewBox="0 0 100 115" preserveAspectRatio="xMidYMin slice">
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="0s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="1s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="2s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
                <polygon points="" fill="none" stroke="hsl(110, 100%, 50%)" strokeWidth="5">
                    <animate attributeName="points" repeatCount="indefinite" dur="4s" begin="3s" from="50 57.5, 50 57.5, 50 57.5" to="50 -75, 175 126, -75 126"></animate>
                </polygon>
            </svg></div>
      
    )
}

export default Background