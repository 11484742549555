import React, { useState } from 'react'
import './Header.scss'
// import LogoShort from '../../assets/images/logo/logo.png'
import LogoImg from '../../assets/images/logo/logo.jpeg'


import closeIcn from '../../assets/images/close.png'
import menuIcn from '../../assets/images/menu.png'

import discordIcn from '../../assets/images/social/discord.png'
import twitterIcn from '../../assets/images/social/twitter.png'
import instaIcn from '../../assets/images/social/insta-logo.png'


function Header() {


    const[mobileNavOpen,setMobilenavOpen]=useState(false)
    const navigate=(link)=>{
        window.open(link)

    }
    const navigateTo=(location)=>{

        var height=0;
        var container = document.getElementsByClassName("App")[0];
        var offsetTop;

        if(window.innerWidth<1250){
            openNavbar()
        }

        switch (location) {
            case "MINT":
              offsetTop =document.getElementsByClassName("MintSection")[0].offsetTop - height;
              container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
              break;
            case "ABOUT":
              offsetTop =document.getElementsByClassName("About")[0].offsetTop - height;
              container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
              break;
            case "CHARITIES":
                offsetTop =document.getElementsByClassName("dev-roadmap")[0].offsetTop- height;
                container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
                break;
            case "ROADMAP":
              offsetTop =document.getElementsByClassName("mint-roadmap")[0].offsetTop- height;
              container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
              break;
            case "TEAM":
              offsetTop =document.getElementsByClassName("team")[0].offsetTop - height;
              container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
              break;
            case "FAQ":
              offsetTop =document.getElementsByClassName("faq-section")[0].offsetTop - height;
              container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
              break;
            default:
              break;
        }
    
    }
    

    const openNavbar=()=>{
        const nav=document.getElementsByClassName('Header-mobile-nav')[0];

        if(!mobileNavOpen){
            nav.style.display='flex';
            setTimeout(()=>{
                nav.style.height='100vh';
                setMobilenavOpen(true)
            },[100])
        }
        else{
            nav.style.height='0vh';
            setTimeout(()=>{
                nav.style.display='none';
                setMobilenavOpen(false)
            },[500])
           
        }
        console.log('init')
    }

    return (
        <div className='Header'>
            <div className='Header-inner'>
                <img className='Header-logo'  src={LogoImg} alt='logo'/>

                <div className='Header-left'>
                    <div className='Header-left-nav'>
                        <span onClick={()=>navigateTo('ABOUT')}  className='font-xs text-white family-orbitron weight-bold'>
                            STORY
                        </span>
                        <span onClick={()=>navigateTo('MINT')} className='font-xs text-white family-orbitron weight-bold'>
                            MINT
                        </span>

                        <span onClick={()=>navigateTo('ROADMAP')}  className='font-xs text-white family-orbitron weight-bold'>
                            ROADMAP
                        </span>
                        <span onClick={()=>navigateTo('TEAM')}  className='font-xs text-white family-orbitron weight-bold'>
                            TEAM
                        </span>
                        <span onClick={()=>navigateTo('FAQ')}  className='font-xs text-white family-orbitron weight-bold'>
                            FAQ
                        </span>
                        
                    </div>
                   
                </div>

                <div className='Header-mobile-nav'>
                    <div className='Header-mobile-nav-inner'>
                        <img   src={LogoImg} alt='logo'/>
                        <span onClick={()=>navigateTo('MINT')} className='font-s text-white family-orbitron weight-bold '>
                            STORY
                        </span>
                        <span onClick={()=>navigateTo('ABOUT')} className='font-s text-white family-orbitron weight-bold '>
                            ABOUT
                        </span>
                       
                        <span onClick={()=>navigateTo('ROADMAP')}  className='font-s text-white family-orbitron weight-bold'>
                            ROADMAP
                        </span>
                        <span onClick={()=>navigateTo('TEAM')}  className='font-s text-white family-orbitron weight-bold'>
                            TEAM
                        </span>
                        <span onClick={()=>navigateTo('FAQ')}  className='font-s text-white family-orbitron weight-bold'>
                            FAQ
                        </span>
                       
                    </div>
                
                </div>
                <div className='header-social-div'>
                    <button onClick={()=>{window.open('')}} className='header-discord-btn'>
                        <img src={discordIcn}/> 
                    </button>
            
                    <button onClick={()=>{window.open('')}}  className='header-twitter-btn'> 
                        <img src={twitterIcn}/>
                    </button>

                    <button onClick={()=>{window.open('')}}  className='header-insta-btn'> 
                        <img src={instaIcn}/>
                    </button>
                </div>
                <button onClick={openNavbar} className='header-menu-btn' >
                    {mobileNavOpen?
                    
                    <img src={closeIcn} alt='logo'/>:
                    <img src={menuIcn} alt='logo'/>}
                </button>
            </div>
            
        </div>
    )
}

export default Header
