import './App.scss';
import { useEffect } from 'react';
import smoothscroll from "smoothscroll-polyfill";

import { ToastContainer } from 'react-toastify';

import "react-toastify/dist/ReactToastify.css";
import Header from './components/header/Header';


import About from './components/about-section/About'

import TeamSection from './components/team-section/TeamSection'

import Footer from './components/footer/Footer'

import MintSection from './components/mint-section/MintSection'

import MintRoadmap from './components/mint-roadmap/MintRoadmap'

import FaqSection from './components/faq-section/FaqSection'

import Carousel from './components/carousel/Carousel'


import Background from './components/background/Background'

import bgImg from './assets/images/header.webp'

window.__forceSmoothScrollPolyfill__ = true;

function App(){

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <div className="App">
      <ToastContainer  position="top-right" autoClose={5000} closeOnClick />
      {/* <Background/> */}
      <Header/>
      <div className="App-inner">
        
        
        <div className='bg-image-holder'>
          <img src={bgImg}/>
        </div>
        
        
        <About/>
        <MintSection/>
        <MintRoadmap/>
        <TeamSection/>
        <FaqSection/>
        <Carousel/>

      </div>
      <Footer/>
    </div>
  );
}

export default App;
