import React, { useEffect, useState } from 'react'
import './Carousel.scss'
import img1 from '../../assets/images/carousel/1.png';
import img2 from '../../assets/images/carousel/2.jpeg';
import img3 from '../../assets/images/carousel/3.png';
import img4 from '../../assets/images/carousel/4.jpeg';
import img5 from '../../assets/images/carousel/5.png';
import img6 from '../../assets/images/carousel/6.jpeg';
import img7 from '../../assets/images/carousel/7.webp';
import img8 from '../../assets/images/carousel/8.webp';
import img9 from '../../assets/images/carousel/9.webp';
import img10 from '../../assets/images/carousel/10.webp';
import img11 from '../../assets/images/carousel/11.webp';
import img12 from '../../assets/images/carousel/12.webp';




const imgNum=12
const projects=imgNum


function Carousel() {
    const[animation,setAnimation]=useState({})
    const[opacity,setOpacity]=useState({})
    const[index,setIndex]=useState(0)
    const [goto, setGoto] = useState(0);
    const[timer,setTimer]=useState({})
    const [status, setStatus] = useState({ progress: 1, state: "original" });
    const [textNum,setTextNum]=useState(0)
    const [width, setWidth] = useState(0);
    const[progress,setProgress]=useState(0)

    function Timer (fn, t,num) {
        var timerObj = setInterval(fn, t);
        
        this.stop = function() {
            if (timerObj) {
                clearInterval(timerObj);
                timerObj = null;
            }
            return this;
        }
    
        // start timer using current settings (if it's not already running)
        this.start = function() {
        
            if (!timerObj) {
                this.stop();
                timerObj = setInterval(fn, t);
            }
            return this;
        }
    
        // start with new or original interval, stop current interval
        this.reset = function(newT = t) {
            t = newT;
            return this.stop().start();
        }
    }   

    useEffect(()=>{
        let classes={};
     
        let imgWidth=document.getElementsByClassName('Carousel-BodyImage')[0].offsetWidth;
        let initialPos=(imgWidth/2)+5;
        let pos=initialPos;
        
        var goto = imgWidth;
        setWidth(imgWidth)
        window.addEventListener('resize',()=>{
           
            imgWidth=document.getElementsByClassName('Carousel-BodyImage')[0].offsetWidth;
            initialPos=(imgWidth/2)+5;
            pos=initialPos-5
     
        })
     

        pos+=imgWidth

        if (status.state !== "original") {
            timer.stop();
            goto = status.goto;
            // textNum=status.textNum
        }
        document.getElementById("Carousel-InnerBody").style.left = `-${pos}px`;
        let timer2 =new Timer(()=>{
            
            document.getElementById("Carousel-InnerBody").style.transition ="left 500ms ease-in-out";
            document.getElementById("Carousel-InnerBody").style.left = `-${pos}px`;
            
            // // if(Math.floor(p)===(Math.floor(iP+(imgPercent*6)))){
            //     console.log(pos,((imgWidth)*imgNum)+(5*(imgNum-1))+initialPos)
            if(pos===(((imgWidth)*imgNum)+(5*(imgNum-1))+initialPos)){
                
         
                pos=initialPos
                setTimeout(()=>{

                    
                    document.getElementById("Carousel-InnerBody").style.transition ="none 0s ease 0s";
                    document.getElementById("Carousel-InnerBody").style.left = `-${pos}px` ;
                   
                    pos+=imgWidth
                    goto += imgWidth;
                    // setGoto(goto);
                },[500])
                    
            }
            else{
                goto += imgWidth;
                // setGoto(goto+imgWidth);
                
                pos+=imgWidth+5
            }
            
        }, 1500);
    
        setTimer(timer2);
        
    },[])
 
    const goBack = () => {
        timer.stop()
        let textNum2=textNum
        textNum2--
        if(textNum2===-1){
          textNum2=4
         
        }
        console.log(textNum2)
        setTimeout(()=>{
          setTextNum(textNum2)
        },[300])
    
    
        let proNum=progress
        proNum-=1
        console.log(proNum)
        if(proNum===0){
          console.log('indeed')
          proNum=5
        }
        let pos = goto - width * 2;
        document.getElementById("Carousel-InnerBody").style.transition ="left 500ms ease-in-out";
        document.getElementById("Carousel-InnerBody").style.left = `-${pos}px`;
    
        if (goto <= width) {
          // proNum=1
          pos = width * (projects.length - 1);
          document.getElementById("Carousel-InnerBody").style.transition ="none 0s ease 0s";
          document.getElementById("Carousel-InnerBody").style.left = `-${pos}px`;
    
          setTimeout(() => {
        
            document.getElementById("Carousel-InnerBody").style.transition =
              "left 500ms ease-in-out";
            document.getElementById("Carousel-InnerBody").style.left = `-${
              pos - width
            }px`;
          }, [100]);
          setGoto(pos);
          setStatus({ goto: pos, state: "New",textNum:textNum2 ,proNum:proNum});
        } else {
          pos += width;
          setGoto(pos);
          setStatus({ goto: pos, state: "New",textNum:textNum2 ,proNum:proNum});
        }
    
        setTimeout(() => {
          setProgress(proNum)
        }, [300]);
      };



    const goForward = () => {
        timer.stop()
        let textNum2=''
        textNum2++
        if(textNum2===5){
            textNum2=0
            
        }
        setTimeout(()=>{
            setTextNum(textNum2)
        },[300])


        let proNum=progress;
        proNum+=1
        if(proNum===projects.length){
            proNum=1
        }
        let pos = goto === 0 ? width : goto;
        document.getElementById("Carousel-InnerBody").style.transition =
            "left 500ms ease-in-out";
        document.getElementById("Carousel-InnerBody").style.left = `-${pos}px`;

        if (goto === width * projects.length) {
            // proNum=1
            document.getElementById("Carousel-InnerBody").style.transition ="none 0s ease 0s";
            document.getElementById("Carousel-InnerBody").style.left = "0px";

            setTimeout(() => {
            document.getElementById("Carousel-InnerBody").style.transition =
                "left 500ms ease-in-out";
            document.getElementById("Carousel-InnerBody").style.left = `-${width}px`;
            }, [100]);
            pos = width * 2;
            setGoto(pos);
            setStatus({ goto: pos, state: "New",textNum:textNum2 ,proNum:proNum });
        } 
        else {
            pos = pos + width;
            setGoto(pos);
            setStatus({ goto: pos, state: "New",textNum:textNum2 ,proNum:proNum });
        }

        setTimeout(() => {
        
            setProgress(proNum)
            
        }, [300]);
    };

    return (
        <div className='Carousel'>
    
            <div className='Carousel-Header'>

                <span className='weight-bold family-orbitron font-xl  text-green bold'>MEET SOME MONSTERS</span>
                <div className='Carousel-Divider'></div>
            </div>
            <div  className='Carousel-Body' id='Carousel-Body'>
               
                <div className='Carousel-InnerBody'  id='Carousel-InnerBody'>
                    <img alt="" src={img12} className="Carousel-BodyImage"></img>
                    <img alt="" src={img1} className="Carousel-BodyImage"></img>
                    <img alt="" src={img2} className="Carousel-BodyImage"></img>
                    <img  alt="" src={img3} className="Carousel-BodyImage"></img>
                    <img  alt="" src={img4} className="Carousel-BodyImage"></img>
                    <img  alt="" src={img5} className="Carousel-BodyImage"></img>
                    <img  alt="" src={img6} className="Carousel-BodyImage"></img>
                    <img  alt="" src={img7} className="Carousel-BodyImage"></img>
                    <img  alt="" src={img8} className="Carousel-BodyImage"></img>
                    <img  alt="" src={img9} className="Carousel-BodyImage"></img>
                    <img  alt="" src={img10} className="Carousel-BodyImage"></img>
                    <img  alt="" src={img11} className="Carousel-BodyImage"></img>
                    <img  alt="" src={img12} className="Carousel-BodyImage"></img>


                    <img  alt="" src={img1} className="Carousel-BodyImage"></img>
                    <img  alt="" src={img2} className="Carousel-BodyImage"></img>
                    <img  alt="" src={img3} className="Carousel-BodyImage"></img>
                </div>
            </div>
          
        </div>
    )
}

export default Carousel




