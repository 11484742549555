import React, { useEffect } from 'react'
import './About.scss'


function About() {

    let observer= new IntersectionObserver(function(entries) {
        let team1=document.getElementsByClassName('About-inner')[0]
    
        for(var i=0;i<entries.length;i++){
            if(entries[i].isIntersecting===true){
        
                switch(Math.round(entries[i].intersectionRatio*10)){
    
                    case 2:
                        team1.style.left='0px';
                        team1.style.opacity='1';
    
                        break;
                    case 3:
                        team1.style.left='0px';
                        team1.style.opacity='1';
                        
                        break;
                }
            
                
            }
        }
    },{ threshold: [0,0.1,0.2,0.3,0.4,0.5,0.6,0.8,0.9,1] });
    
    useEffect(()=>{
        observer.observe(document.getElementsByClassName('About')[0]);
    },[])
    
    


    return (
        <div className='About'>
            <div className='About-inner'>
                
                <span className='font-xl family-orbitron weight-semi-bold text-green'>
                    THE STORY
                </span>
                <div className='font-s text-white family-orbitron weight-light line-height-33' >
                <br/> 
                    In the year 2025, in the parallel word, Monsters roamed the lands freely. Scrounging for food and water, day and night. It’s hard to say how long these creatures have been around but not once did they encounter another species. That day finally came when thousands of the Monsters were captured by humans. Hundreds of mad scientists came together in a desperate act to find out what these Monsters were and how they came to be. Years of experiments being poked, prodded and sliced open saw that the species would be extinct within the next few months, but that all changed when one procedure didn’t go to plan. One of the Monsters was given a toxic substance called “The Juice” which gave it incredible abilities allowing him to take his revenge on all who had wronged the species. With that freeing the remaining 9999 MonstersNFT into the human world. Will they cause chaos, or will they be looking for a way home?

                </div>
            
            </div>
        </div>
    )
}

export default About
