import React from 'react'
import './Footer.scss'
import logoImg from '../../assets/images/logo/logo.jpeg'

import discordIcn from '../../assets/images/social/discord.png'
import twitterIcn from '../../assets/images/social/twitter.png'
import instaIcn from '../../assets/images/social/insta-logo.png'

function Footer() {
    return (
        <div className='footer'>
            <div className='footer-inner'>
                <div className='footer-inner-left'>
                    <img className='footer-logo'  src={logoImg} alt='logo'/>
                    <span className='text-white font-s family-orbitron'>
                        All rights reserved to Monsters NFT
                    </span>
                </div>
               
                <div className='footer-social-div'>
                    <button onClick={()=>{window.open('')}} className='footer-discord-btn'>
                        <img src={discordIcn}/> 
                    </button>
            
                    <button onClick={()=>{window.open('')}}  className='footer-twitter-btn'> 
                        <img src={twitterIcn}/>
                    </button>

                    <button onClick={()=>{window.open('')}}  className='footer-insta-btn'> 
                        <img src={instaIcn}/>
                    </button>
                </div>
            </div>
            
        </div>
    )
}

export default Footer
