import React, { useEffect } from 'react'
import './MintRoadmap.scss'
function MintRoadmap() {
    let observer= new IntersectionObserver(function(entries) {
        // let progress=document.getElementsByClassName('project-progress-inner')[0]
        for(var i=0;i<entries.length;i++){
          if(entries[i].intersectionRect.top>0&&entries[i].isIntersecting===true){
            switch(Math.round(entries[i].intersectionRatio*10)){
                case 0:
                    entries[i].target.style.top='-70px';
                    entries[i].target.style.opacity=`1`;
                   
                    break;
                case 1:
                    entries[i].target.style.top='-70px';
                    entries[i].target.style.opacity=`1`;

                    break;
                case 2:
                    entries[i].target.style.top='-70px';
                    entries[i].target.style.opacity=`1`;

                    break;
                case 3:
                    entries[i].target.style.top='-70px';
                    entries[i].target.style.opacity=`1`;

                    break;
                case 4:
                    entries[i].target.style.top='-70px';
                    entries[i].target.style.opacity=`1`;
                    // progress.style.bottom='600px';

                    break;
                case 5:
                    entries[i].target.style.top='-70px';
                    entries[i].target.style.opacity=`1`;

                    break;
                case 6:
                    entries[i].target.style.top='-70px';
                    entries[i].target.style.opacity=`1`;

                    break;
                case 7:
                    entries[i].target.style.top='-70px';
                    entries[i].target.style.opacity=`1`;
                    break;
                case 8:
                    entries[i].target.style.top='-70px';
                    entries[i].target.style.opacity=`1`;

                    break;
                case 9:
                    entries[i].target.style.top='-70px';
                    entries[i].target.style.opacity=`1`;

                    break;
                case 10:
                    entries[i].target.style.top='-70px';
                    entries[i].target.style.opacity=`1`;

                    break;
                default:
                    break;
            }
          }
        }
    },{ threshold: [0,0.1,0.2,0.3,0.4,0.5,0.6,0.7,0.8,0.9,1] });

    useEffect(()=>{
        observer.observe(document.getElementById('mint-road1'));
        observer.observe(document.getElementById('mint-road2'));
        observer.observe(document.getElementById('mint-road3'));
        observer.observe(document.getElementById('mint-road4'));
        observer.observe(document.getElementById('mint-road5'));
    },[])
    return (
        <div className='mint-roadmap'>
            <div className='font-xl text-green weight-semi-bold family-orbitron mint-roadmap-title'>
                ROADMAP
            </div>
            {/* <div className='blockers'></div> */}
            <div className='mint-roadmap-inner'>
                
                <div className='project-progress'>
                    {/* <div className='project-progress-inner'></div> */}
                </div>
                <div id='mint-road1' style={{top:'-70px'}} className='mint-roadmap-section'>
                    <div className='mint-roadmap-circle text-white font-s family-mono weight-bold'>10%</div>
                    <span className='font-m weight-bold family-orbitron text-green'>THE MINT</span>
                    <br/>
                    <br/>

                    <p className='font-xs family-mono text-white'>
                        Official Monsters NFT mint. 10,000 Monsters living on the Ethereum blockchain will find their way to their rightful owners and start one crazy journey. If you’re not already in the discord get involved, probably the best decision you’ll ever make.
                    </p>
                </div>
                <div id='mint-road2'  className='mint-roadmap-section'>
                    <div className='mint-roadmap-circle text-white font-s family-mono weight-bold'>20% </div>
                    <span className='font-m weight-bold family-orbitron text-green'> THE JUMP </span>
                    <br/>
                    <br/>
                    <p className='font-xs family-mono text-white'>
                        After mint there will be an exclusive air drop of Monsters to the top community members, and yes there will be a few rare ones taking the jump!
                    </p>
                </div>
                <div id='mint-road3' className='mint-roadmap-section'>
                    <div className='mint-roadmap-circle text-white font-s family-mono  weight-bold'>30%</div>
                    <span className='font-m weight-bold family-orbitron text-green'>THE MONEY</span>
                    <br/>
                    <br/>
                    <p className='font-s family-mono text-white'>
                        Mad Monster Marketing will be applied throughout the minting stage, there will be no holding back when it comes to making the Monster NFT the NFT everyone wants.                   
                    </p>
                </div>
                <div id='mint-road4' className='mint-roadmap-section'>
                    <div className='mint-roadmap-circle text-white font-s family-mono  weight-bold'>40%</div>
                    <span className='font-m weight-bold family-orbitron text-green'> THE LOTO</span>
                    <br/>
                    <br/>
                    <p className='font-s family-mono text-white'>
                        Who likes free money? Every week 20% of royalties (reselling of nft’s) will be given back to the community as a random lottery between holders. The more Monsters you hold the more chance of winning that cash pot.                
                    </p>
                </div>
                <div  id='mint-road5' className='mint-roadmap-section'>
                    <div className='mint-roadmap-circle text-white font-s family-mono weight-bold'>50%</div>
                    <span className='font-m weight-bold family-orbitron text-green'>THE COMMUNITTY</span>
                    <br/>
                    <br/>

                    <p className='font-Xs family-mono text-white'>
                     Everything starts and ends with community. Its hard to put into words how much community truly means to us. So, what better way to show you than let you guys build the last 50% of the Monsters NFT roadmap. Now seeing as were all about giving, there will be funds raised for 5 different charities of the community’s choice. This will take place by a poll on the discord.                    
                    </p>
                </div>
            
            </div>
           
        </div>
        
    )
}

export default MintRoadmap
