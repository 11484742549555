import React, { useEffect } from 'react'
import './TeamSection.scss'

import img1 from '../../assets/images/team/1.png'
import img2 from '../../assets/images/team/2.png'
import img3 from '../../assets/images/team/3.png'
import img4 from '../../assets/images/team/4.png'


function StorySection() {

    

    let observer= new IntersectionObserver(function(entries) {
        let team1=document.getElementById('team1')
        let team2=document.getElementById('team2')
        let team3=document.getElementById('team3')
        let team4=document.getElementById('team4')
        

        const editPos=()=>{
            team1.style.top='0px';
            team1.style.opacity='1';
            team2.style.top='0px';
            team2.style.opacity='1';
            team3.style.top='0px';
            team3.style.opacity='1';
            team4.style.top='0px';
            team4.style.opacity='1';
           
        }


        for(var i=0;i<entries.length;i++){
            if(entries[i].isIntersecting===true){
                if(window.innerWidth>669){
                    switch(Math.round(entries[i].intersectionRatio*10)){
                        case 1:
                            editPos()
                            break;
                       
                        case 2:
                            editPos()
                            break;
                        case 3:
                            editPos()
                            break;
                        
                    }
                }
                else{
                    switch(Math.round(entries[i].intersectionRatio*10)){
        
                        case 0:
                            editPos()
                            break;
                        case 1:
                            editPos()
                            break;
                        case 2:
                            editPos()
                            break;
                        case 3:
                            editPos()
                            break;
                }
                }
            }
        }
    },{ threshold: [0,0.1,0.2,0.3,0.4,0.5,0.6,0.8,0.9,1] });

    useEffect(()=>{
        observer.observe(document.getElementsByClassName('team')[0]);
    },[])



    return (
        <div className='team'>
            <div className='team-inner'>
                <div className='font-xl text-green weight-semi-bold family-orbitron'>
                    THE TEAM
                </div>
                <div className='team-inner-bottom family-orbitron'>
                    
                    <div id='team1' className='team-wrapper'>
                        <img src={img1}/>
                        <div className='family-grand-bold text-white font-s mt-1 weight-bold'>
                        The Jay Monster
                        </div>
                        <p className='text-white font-xs family-wide'>Co - founder</p>

                    </div>

                    <div  id='team2' className='team-wrapper'>
                        <img src={img2}/>
                        <div className='family-grand-bold text-white font-s mt-1 weight-bold'>
                            The Jez Monster 
                        </div>
                        <p className='text-white font-xs family-wide'>Co - founder</p>

                    </div>

                    <div id='team3' className='team-wrapper '>
                        <img src={img3}/>
                        <div className='family-grand-bold text-white font-s mt-1 weight-bold'>
                            Zane   
                        </div>
                        <p className='text-white font-xs family-wide'>Graphic designer</p>
                    </div>

                    

                    <div id='team4' className='team-wrapper'>
                        <img src={img4}/>
                        <div className='family-grand-bold text-white font-s mt-1 weight-bold'>
                            Dom   
                        </div>
                        <p className='text-white font-xs family-wide'>Social media & Influence manager </p>

                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default StorySection
